import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Work from '../views/Work.vue'
import Licenses from '../views/Licenses.vue'
import SocialMedia from '../views/SocialMedia.vue'
import Users from '../views/Users.vue'
import LoginPage from '../views/LoginPage.vue'


import LayoutPage from '../views/LayoutPage.vue'

const routes = [
  {
    path: '/',
    name: 'LoginPage',
    component: LoginPage
  },
  {
    path: '/LayoutPage',
    name: 'LayoutPage',
    component: LayoutPage,
    children: [
      {
        path: '/home',
        name: 'Home',
        component: Home
      },
      {
        path: '/about',
        name: 'About',
        component: About
      },
      {
        path: '/work',
        name: 'Work',
        component: Work
      },
      {
        path: '/licenses',
        name: 'Licenses',
        component: Licenses
      },
      {
        path: '/social-media',
        name: 'SocialMedia',
        component: SocialMedia
      },
      {
        path: '/Users',
        name: 'Users',
        component: Users
      },
    ]
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
