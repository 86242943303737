<template>
    <div id="side_bar" :class=" [ sideBarStatus ? 'right-0' : '-right-64' , 'w-64 h-full bg-white py-4 px-4 shadow fixed duration-700 z-40']">
        <div class="sidebar-toggle bg-main text-xl text-white flex-center w-12 h-12 rounded-full absolute -left-16 shadow cursor-pointer" @click="toggleSideBarStatus()">
            <i class="fas fa-bars"></i>
        </div>
        <div class="tiltle text-center  mb-5">
            <h1 class = "text-2xl text-main font-bold">لــوحة التحكم</h1>
            <span class = "block text-gray-400 font-bold">{{getUserName}}</span>
            <span class = "block text-gray-400">{{getUserEmail}}</span>
        </div>
        <div class="sidebar-list">
            <ul>
                <li class = "mb-1" v-for = "item in sidebarContent" :key="item">
                    <router-link active-class="active" :to = "item.link" class = "flex items-center justify-between py-1 text-gray-500 rounded hover:bg-main text-md hover:text-white duration-500 px-2">
                        <span>{{item.title}}</span>
                        <span>
                            <i :class="item.icon" class = "w-10 text-center"></i>
                        </span>
                    </router-link>
                </li>
                <li class = "mb-1 cursor-pointer" @click="logOut()">
                    <a class = "flex items-center justify-between py-1 text-gray-500 rounded hover:bg-main text-md hover:text-white duration-500 px-2">
                        <span>تسجيل خــروج</span>
                        <span>
                            <i class="fas fa-sign-out-alt w-10 text-center"></i>
                        </span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            sidebarContent:[
                {
                    title:'عن المؤسسة',
                    link:'/about',
                    icon:'fas fa-info'
                },
                {
                    title:'ألاعمال',
                    link:'/Work',
                    icon:'fas fa-save'
                },
                {
                    title:'التراخيص',
                    link:'/licenses',
                    icon:'fas fa-solid fa-clipboard-check'
                },
                {
                    title:'تواصل الاجتماعي',
                    link:'/social-media',
                    icon:'fas fa-share-alt-square'
                },
                {
                    title:'البروفايل',
                    link:'/users',
                    icon:'fas fa-solid fa-id-badge'
                },
            ]
        }
    },
    methods:{
        logOut(){
            localStorage.removeItem('sama-info')
            this.$router.push('/')
        }
    }
}
</script>