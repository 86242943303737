import axios from "axios";
import store from "@/store/index.js"
import Swal from 'sweetalert2'

axios.interceptors.request.use(config => {
    config.baseURL = "https://api.sama-iq.info/api",
    config.headers.Authorization = `Base ${ localStorage.getItem('il_user_Info') !== null ? JSON.parse(localStorage.getItem('il_user_Info')).auth : ''}`
    config.method == "get" ? store.state.getLoader = true : store.state.postLoader = true
    config.headers = {
        'Access-Control-Allow-Origin':'*',
        'Authorization': `Bearer ${ localStorage.getItem('sama-info') !== null ? JSON.parse(localStorage.getItem('sama-info')).token : ''}`,
        'Accept':'application/json'
    }
    return config;

})

axios.interceptors.response.use(response => {
    response.config.method == "get" ? store.state.getLoader = false : store.state.postLoader = false
    if(response.config.method !== 'get'){
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Procciss Done' ,
            showConfirmButton: false,
            timer: 1500
        })
    }
    return response
}, (error)=>{
    store.state.getLoader = false 
    store.state.postLoader = false 

    Swal.fire({
        icon: 'error',
        title: 'Oops...',
        showConfirmButton: false,
        text: error.response.data.message,
        footer: ''
    })

    return Promise.reject(error);

}
)

export default axios ; 