<template lang="pug">
<div id="ProjectsPage" class = "pb-8">
    <min-nav></min-nav>
    <page-header title = "التراخيص" icon="fas fa-save"></page-header>
    
    <div :class = "[ editStatus ? 'active': '' , 'edit-form']">
        <form action="" class = "grid grid-cols-1 w-11/12 md:w-8/12 gap-3 bg-white rounded px-3 py-5 max-h-full overflow-auto">
            <div class="input">
                <label for="" class = "requier"  > العنوان (أنكليزي) </label>
                <input type="text" v-model="editData.title_en" class = "field">
            </div>

            <div class="input">
                <label for="" class = ""> العنوان (عربي) </label>
                <input type="text" v-model="editData.title_ar" class = "field">
            </div>

            <div class="controll col-span-full flex justify-end py-2 ">
                <button class = " btn sub-btn" @click.prevent="closeEdit()"> غلق </button>
                <button class = "btn main-btn" :class = "[postLoader ? 'opacity-60 cursor-not-allowed' : '' , 'btn main-btn']" :disabled = "postLoader" @click="update()"> تعديل </button>
            </div>

        </form>
    </div>

    <div class="add-form -mt-10 bg-white w-11/12 mx-auto py-4 rounded shadow mb-3">
        <div class="container">
            <div class = "section-title">
                <span class = "text-xl">Add Items Form</span>
            </div>
            <form action="" class = "grid grid-cols-1  gap-2">
                <div class="img-section">
                    <div class="upload">
                        <input type="file" @change="getCoverImg($event)"  accept="image/png, image/jpeg">
                        <i class="fas fa-cloud-upload-alt text-4xl"></i>
                        <span class = "text-xl underline"> رفع صورة </span>
                    </div>
                    <div class="preview">
                        <div class="img-title flex-center flex-col" v-show="postData.cover == '' ? true : false">
                            <i class="fas fa-photo-video text-4xl"></i>
                            <span class = "text-xl font-bold"> عرض الصورة </span>
                        </div>
                        <img :src="postData.cover"  v-show="postData.cover == ''?false:true" class = "h-60 object-cover" alt="">
                    </div>
                </div>
                <div class="input">
                    <label for="" class = "requier"  > العنوان (انكليزي) </label>
                    <input type="text" v-model="postData.title_en" class = "field">
                </div>
                <div class="input">
                    <label for="" class = ""> العنوان (عربي) </label>
                    <input type="text" v-model="postData.title_ar" class = "field">
                </div>
                <div class="controll col-span-full flex justify-end py-2">
                    <button class = "btn main-btn" @click.prevent="post()" :class = "[postLoader ? 'opacity-60 cursor-not-allowed' : '' , 'btn main-btn']" :disabled = "postLoader"> أضافة </button>
                </div>

            </form>
        </div>
    </div>
    <div class="list-form">
        <div class="container">
            <div class = "section-title">
                <span class = "text-xl">قائمة الاعمال</span>
            </div>
            
            
            //- <div class="filters" @change = "filtering()">
            //-     <div class="name input">
            //-         <label for="" class = ""> Filter By Name </label>
            //-         <input type="search" placeholder = "Filter By Name" class = "field" v-model="filterData.project_title_en">
            //-     </div>
            //-     <div class="visible input">
            //-         <label for="" class = ""> Filter By Visible </label>
            //-         <select class = "field" name="" id="" v-model="filterData.visible">
            //-             <option value="both">Both</option>
            //-             <option value="true">active</option>
            //-             <option value="false">Disactive</option>
            //-         </select>
            //-     </div>
            //-     <div class="status input">
            //-         <label for="" class = ""> Filter By Status </label>
            //-         <select class = "field" name="" id="" v-model="filterData.active">
            //-             <option value="both">Both</option>
            //-             <option value="true">active</option>
            //-             <option value="false">Disactive</option>
            //-         </select>
            //-     </div>
            //- </div> 
            
            <div class="list relative">
                <get-loader class = "absolute inset-0"></get-loader>
                table(id = "myTable" class = "w-full over")
                    thead(class = " text-left text-gray-500 text-sm md:text-lg")
                        tr
                            th(class = " pl-4 border-b-2") #
                            th(class = " pl-4 border-b-2") الغلاف
                            th(class = " pl-4 border-b-2") العنوان عربي
                            th(class = " pl-4 border-b-2") العنوان انكليزي
                            th(class = " pl-4 border-b-2") تعديل
                            
                            
                    tbody
                        tr(class='odd:bg-white even:bg-gray-100 duration-500 text-md' v-for = "(licens,index) in licenses" :key="licens.id")
                            td(class = "font-bold lg:pl-4 border-b py-0 md:py-3") {{index + 1}}
                            td(class = "font-bold lg:pl-4 border-b py-0 md:py-3") 
                                a(:href = "licens.content.cover" target = "_blanck")
                                    img(:src = "licens.content.cover" class = "w-12 h-12 object-cover rounded")
                            td(class = "font-bold lg:pl-4 border-b py-0 md:py-3") {{licens.content.title_ar}}
                            td(class = "font-bold lg:pl-4 border-b py-0 md:py-3") {{licens.content.title_en}}

                            td(class = "border-b py-0 md:py-3")
                                ul(class = "list flex items-center")
                                    li(class = "text-gray-500 text-xl cursor-pointer mr-3 duration-500 hover:text-blue-500" @click="editInit(licens)")
                                        <i class="fas fa-pencil-alt"></i>
                                
                                    li(class = "text-gray-500 text-xl cursor-pointer mr-3 duration-500 hover:text-red-500" @click="delItem(licens)")
                                        <i class="fas fa-trash-alt"></i>
            </div>
        </div>
    </div>
</div>
</template>
<style>
    header{
        border-radius: 0 0 50% 50%;
    }
                    @media 
only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px) {
	#ProjectsPage table td:nth-of-type(1):before { content: "#"; }
	#ProjectsPage table td:nth-of-type(2):before { content: "الغلاف"; }
	#ProjectsPage table td:nth-of-type(3):before { content: "العنوان عربي"; }
	#ProjectsPage table td:nth-of-type(4):before { content: "العنوان انكليزي"; }
	#ProjectsPage table td:nth-of-type(5):before { content: "التعديل"; }
}
</style>
<script>
import axios from 'axios'
export default {
    data(){
        return{
            showImg:'',
            showEditImg:'',
            types:[],
            postData:{
                title_en:'',
                title_ar:'',
                cover:'',
            },
            editData:{},
            tempData:{},
            column:{
                img_info:{
                    name:'Img',
                    key:'primary_image'
                },
                other_value:[
                    {
                        name:'Title',
                        key:'project_title_en'
                    },
                    {
                        name:'Body',
                        key:'sub_body'
                    },
                    {
                        name:'Project Type',
                        key:'project_type_title_en'
                    },
                    {
                        name:'Visible',
                        key:'visible'
                    },
                    {
                        name:'status',
                        key:'status'
                    },
                ],
                config:{
                    index: true,
                    img: true,
                    attach: false,
                    pagin: false,
                    filters:false,
                    actions:true,
                    filter:['Seasons' , 'Name' , "Status"],
                    action:['Edit','Delete'],
                    link:''
                },
            }
        }
    },
    methods:{
        filtering(){
            this.getProjects(this.filterData)
        },
        getCoverImg(event){
            console.log(event.target.files)
            for ( var key in event.target.files ){
                
                axios.post('/image',this.dataReutrn({image:event.target.files[key]})).then(response =>{
                    this.postData.cover = response.data.data
                    console.log(this.postData)
                })
            }
        },
        post(){
           this.createLicens({"content":JSON.stringify(this.postData)}).then(()=>{
                this.postData={
                    title_en:'',
                    title_ar:'',
                    cover:'',
                }
                this.getLicenses()
           })
        },
        editInit(licens){
            this.toggleEditStatus()         
            this.editData = {
                id:licens.id,
                title_en : licens.content.title_en,
                title_ar : licens.content.title_ar,
                cover : licens.content.cover, 
            } 
        },
        update(){
            this.updateLicens(this.editData).then(()=>{
                this.toggleEditStatus()
                this.getLicenses()
           })
        },
        delItem(licens){
            if(confirm(this.alertMsg)){
                this.deleteLicens(licens.id).then(()=>{
                    this.getLicenses()
                })
            }
        },
        closeEdit(){
            this.toggleEditStatus()        
        }
    },
    watch:{
        projectTypes(value){
            this.types = value
        }
    },
    mounted(){
        this.getLicenses()
    }
}
</script>