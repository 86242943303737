import $http from "@/plugins/axios.js"

export default {
    state:{
        socials:[]
    },
    getters:{
        socials: state => state.socials
    },
    actions:{
        async getSocials({commit}){
            const response = await $http.get(`/contact`)
            commit("SET_SOCIAL", response.data);
        },

        async createSocial({commit},social){
            await $http.post('/contact',{"content":social})
            //commit('ADD_NEW_SOCIAL',response.data.obj)
            return commit
        },

        async updateSocial({commit} , social){
            await $http.post(`/contact`,{"content":social})
            return commit
            //commit('UPDATE_SOCIAL',response.data.obj)
        },
        async deleteSocial({commit} , id){
            const response = await $http.delete(`/SocialMedias/${id}`)
            commit('DELETE_SOCIAL',response.data.obj.id)
        }
    },
    mutations:{
        SET_SOCIAL(state,data){
            console.log(data.data)
            state.socials = JSON.parse(data.data)
        },
        ADD_NEW_SOCIAL(state,data){
            state.socials.push(data)
        },
        UPDATE_SOCIAL(state,data){
            state.socials  = state.socials.map(item => item = item.id == data.id ? data : item)
        },
        DELETE_SOCIAL(state,id){
            state.socials  = state.socials.filter(item => item.id !== id)
        },
        
    }
}