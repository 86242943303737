import $http from "@/plugins/axios.js"

export default {
    state:{
        licenses:[]
    },
    getters:{
        licenses: state => state.licenses
    },
    actions:{
        async getLicenses({commit}){
            const response = await $http.get(`/licenses`)
            response.data.data.data.map(item=>{
                item.content = JSON.parse(item.content)
            })
            commit("SET_PROJECTS", response.data.data.data.reverse());
        },

        async createLicens({commit},licenses){
            await $http.post('/licenses',licenses)
            return commit
            // commit('ADD_NEW_PROJECT',response.data.data)
        },

        async updateLicens({commit} , licenses){
            await $http.put(`/licenses/${licenses.id}`,{"content":JSON.stringify(licenses)})
            return commit
        },
        async deleteLicens({commit} , id){
            await $http.delete(`/licenses/${id}`)
            return commit
        }
    },
    mutations:{
        SET_PROJECTS(state,data){
            state.licenses = data
        },
        ADD_NEW_PROJECT(state,data){
            state.projects.push(JSON.parse(data))
        },
        UPDATE_PROJECT(state,data){
            state.projects  = state.projects.map(item => item = item.id == data.id ? data : item)
        },
        DELETE_PROJECT(state,id){
            state.projects  = state.projects.filter(item => item.id !== id)
        },
        
    }
}