<template>
  <div class="home"> 
    <h1> test </h1>
  </div>
</template>

<script>
export default {
  name: 'Home',
}
</script>
