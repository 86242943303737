<template>
    <div id="Social" class = "pb-8">
        <min-nav></min-nav>
        <page-header title = "التواصل الاجتماعي" icon="fas fa-share-alt-square"></page-header>
        
        <div :class = "[ editStatus ? 'active': '' , 'edit-form']">
            <form action="" class = "grid grid-cols-1 gap-2 w-11/12 md:w-8/12 bg-white rounded px-3 py-5 max-h-full overflow-auto">
                <div class="input">
                        <label for="" class = "">أسم الموقع (أنكليزي)</label>
                        <input type="text" placeholder = "Name" v-model="editData.name_en" class = "field">
                    </div>
                    
                    <div class="input">
                        <label for="" class = "">أسم الموقع (عربي)</label>
                        <input type="text" placeholder = "Name" v-model="editData.name_ar" class = "field">
                    </div>
                    <div class="input">
                        <label for="" class = ""> الرابط </label>
                        <input type="text" placeholder = "Link" v-model="editData.url" class = "field">
                    </div>

                <div class="controll col-span-full flex justify-end ">
                    <button class = " btn sub-btn" @click.prevent="closeEdit()"> غلق </button>
                    <button class = "btn main-btn" :class = "[postLoader ? 'opacity-60 cursor-not-allowed' : '' , 'btn main-btn']" :disabled = "postLoader" @click.prevent="update()"> تعديل </button>
                </div>

            </form>
        </div>
        <div class="add-form -mt-10 bg-white w-11/12 mx-auto py-4 rounded shadow mb-3">
            <div class="container">
                <div class = "section-title">
                    <span class = "text-xl">اضافة موقع تواصل</span>
                </div>
                <form action="" class = "grid grid-cols-1 gap-2">

                    <div class="input">
                        <label for="" class = "">أسم الموقع (أنكليزي)</label>
                        <input type="text" v-model="postData.name_en" class = "field">
                    </div>
                    
                    <div class="input">
                        <label for="" class = "">أسم الموقع (عربي)</label>
                        <input type="text" v-model="postData.name_ar" class = "field">
                    </div>
                    <div class="input">
                        <label for="" class = ""> الرابط </label>
                        <input type="text" v-model="postData.url" class = "field">
                    </div>

                    <div class="controll col-span-full flex justify-end">
                        <button class = "btn main-btn" @click.prevent="post()" :class = "[postLoader ? 'opacity-60 cursor-not-allowed' : '' , 'btn main-btn']" :disabled = "postLoader"> أضافة </button>
                    </div>

                </form>
            </div>
        </div>
        <div class="list-form">
            <div class="container">
                <div class = "section-title">
                    <span class = "text-xl">قائمة المواقع</span>
                </div>
                
                <div class="list">
                    <data-table :cols = "column" :data = "socials" @edit = "editInit" @deletItem = "delItem"></data-table>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
    header{
        border-radius: 0 0 50% 50%;
    }
                            @media 
only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px) {
	#Social table td:nth-of-type(1):before { content: "#"; }
	#Social table td:nth-of-type(2):before { content: "الاسم عربي"; }
	#Social table td:nth-of-type(3):before { content: "الاسم انكليزي"; }
	#Social table td:nth-of-type(4):before { content: "الرابط"; }
	#Social table td:nth-of-type(5):before { content: "التعديل"; }
}
</style>
<script>
export default {
    data(){
        return{
            showImg:'',
            showEditImg:'',
            postData:{
                name_ar:'',
                name_en:'',
                url:'',
            },
            oldData:[],
            editData:{},
            tempData:{},
            filterData:{
                project_id:0
            },
            column:{
                other_value:[
                    {
                        name:'الاسم العربي',
                        key:'name_ar'
                    },
                    {
                        name:'الاسم الانكليزي',
                        key:'name_en'
                    },
                    {
                        name:'الرابط',
                        key:'url'
                    },
                ],
                config:{
                    index: true,
                    img: false,
                    attach: false,
                    pagin: false,
                    filters:false,
                    actions:true,
                    filter:['Seasons' , 'Name' , "Status"],
                    action:['Edit','Delete'],
                    link:''
                },
            }
        }
    },
    computed:{
        showImgCh(){
           return this.showImg == "" ? true : false
        },
        showEditImgCh(){
            return this.showEditImg == "" ? true : false
        }
    },
    methods:{
        filtering(){
            this.getProjects(this.filterData)
        },
        post(){
            this.oldData.push(this.postData)
            this.createSocial(JSON.stringify(this.oldData)).then(()=>{
                this.showImg='',
                this.postData={
                    name_ar:'',
                    name_en:'',
                    url:'',
                }
            })
        },
        editInit(item){
            this.toggleEditStatus()
            this.showEditImg = this.baseUrl + item.icon
            this.editData = item            
            this.tempData = {
                id:item.id,
                name_en:item.name_en,
                name_ar:item.name_ar,
                url : item.url,
                icon : item.icon,
                status : item.status,
            } 
        },
        update(){
            this.oldData  = this.oldData.map(item => item = item.id == this.editData.id ? this.editData : item)
           this.updateSocial(JSON.stringify(this.oldData)).then(()=>{
               this.toggleEditStatus()
               this.getSocials()
           })
        },
        delItem(item){
            this.oldData = this.oldData.filter(el => el.name_ar !== item.name_ar)
            if(confirm(this.alertMsg)){
                this.updateSocial(JSON.stringify(this.oldData)).then(()=>{
                    this.getSocials()
                })
            }
        },
        closeEdit(){
            this.socials[
                this.socials.findIndex(item => {return item.id == this.tempData.id})
            ] = this.tempData
            this.toggleEditStatus()        
        }
    },
    mounted(){
        this.getSocials().then(()=>{
            this.socials.map(item=>{
                this.oldData.push(item)
                return item
            })
        })
    }
}
</script>