import $http from "@/plugins/axios.js"

export default {
    state:{
        user:{}
    },
    getters:{
        user: state => state.user
    },
    actions:{
        async getUser({commit}){
            const response = await $http.get(`/profile`)
          
            commit("SET_USER", response.data.data);
        },
        async updateUser({commit} , user){
            await $http.put(`/profile`,user)
            return commit
        }
    },
    mutations:{
        SET_USER(state,data){
            state.user = data
        },
        ADD_NEW_USER(state,data){
            state.users.push(data)
        },
        UPDATE_USER(state,data){
            state.users  = state.users.map(item => item = item.id == data.id ? data : item)
        },
        
    }
}