<template>
    
    <div id="UserProfile" class = "h-screen flex items-center justify-center">
        <form action="" class = "relative rounded-xl bg-white shadow-sm flex flex-col gap-2 items-center justify-center w-80 h-80">
            <get-loader class = "absolute inset-0 z-40"></get-loader>
            <input type="text" class = "w-10/12 UserName text-center bg-gray-200 font-bold py-1 focus:bg-white border  focus:border-main duration-300 " v-model = "postData.name" placeholder="Name">
            <input type="text" class = "w-10/12 email text-center bg-gray-200 font-bold py-1 focus:bg-white border  focus:border-main duration-300" v-model = "postData.email" placeholder="Email">
            <input type="text" class = "w-10/12 password text-center bg-gray-200 font-bold py-1 focus:bg-white border  focus:border-main duration-300" v-model = "postData.password" placeholder="Password">
            <input type="text" class = "w-10/12 old-password text-center bg-gray-200 font-bold py-1 focus:bg-white border  focus:border-main duration-300" v-model = "postData.old_password" placeholder="Old Password">
            <button @click.prevent="updateInfo()" class = " w-10/12 bg-main text-white font-bold py-1 disabled:opacity-40 disabled:cursor-not-allowed" :disabled = "postData.old_password.length > 0 ? false : true"> Save </button>
        </form>
    </div>
</template>

<script>
export default {
    data(){
        return{
            postData:{
                name:'',
                email:'',
                password:'',
                old_password:''
            }
        }
    },
    methods:{
        updateInfo(){
            this.postData.password == "" ? delete this.postData.password : 'nothing'
            this.updateUser(this.postData).then(()=>{
                this.getUser()
                this.postData.old_password = ""
            })
        }
    },
    mounted(){
        this.getUser().then(()=>{
            this.postData.name = this.user.name
            this.postData.email = this.user.email
        })
    }
}
</script>
