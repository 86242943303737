<template lang="pug">
<div id="ProjectsPage" class = "pb-8">
    <min-nav></min-nav>
    <page-header title = "أعمالنا" icon="fas fa-save"></page-header>
    
    <div :class = "[ editStatus ? 'active': '' , 'edit-form']">
        <form action="" class = "grid grid-cols-1 md:grid-cols-2 w-11/12 md:w-8/12 gap-3 bg-white rounded px-3 py-5 max-h-full overflow-auto">
            <div class="input col-span-full">
                <label for="" class = "requier"> التاريخ </label>
                <input type="date" v-model = "editData.date"  class = "field">
            </div>
            
            <div class="input">
                <label for="" class = "requier"  > العنوان (أنكليزي) </label>
                <input type="text"  v-model="editData.title_en" class = "field">
            </div>

            <div class="input">
                <label for="" class = ""> العنوان (عربي) </label>
                <input type="text"  v-model="editData.title_ar" class = "field">
            </div>
            
            <div class="input">
                <label for="" class = "requier"> الوصف (أنكليزي) </label>
                <textarea  v-model="editData.body_en" class = "field h-40"></textarea>
            </div>
            
            <div class="input">
                <label for="" class = ""> الوصف (عربي) </label>
                <textarea class = "field h-40" v-model="editData.body_ar"></textarea>
            </div>

            <div class="controll col-span-full flex justify-end py-2 ">
                <button class = " btn sub-btn" @click.prevent="closeEdit()"> غلق </button>
                <button class = "btn main-btn" :class = "[postLoader ? 'opacity-60 cursor-not-allowed' : '' , 'btn main-btn']" :disabled = "postLoader" @click="update()"> تعديل </button>
            </div>

        </form>
    </div>

    <div class="add-form -mt-10 bg-white w-11/12 mx-auto py-4 rounded shadow mb-3">
        <div class="container">
            <div class = "section-title">
                <span class = "text-xl">أضافة عمل</span>
            </div>
            <form action="" class = "grid grid-cols-1 md:grid-cols-2  gap-2">
                <div class="img-section">
                    <div class="upload">
                        <input type="file" @change="getCoverImg($event)"  accept="image/png, image/jpeg">
                        <i class="fas fa-cloud-upload-alt text-4xl"></i>
                        <span class = "text-xl underline"> صورة الغلاف </span>
                    </div>
                    <div class="preview">
                        <div class="img-title flex-center flex-col" v-show="postData.cover == '' ? true : false">
                            <i class="fas fa-photo-video text-4xl"></i>
                            <span class = "text-xl font-bold"> عرض لصورة </span>
                        </div>
                        <img :src="postData.cover"  v-show="postData.cover == ''?false:true" class = "h-60 object-cover" alt="">
                    </div>
                </div>
                <div class="show-all-work-img col-span-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4  p-4 border-4 border-dashed rounded-lg" v-show="postData.imgs.length > 0 ? true : false">
                    <div class="imgs-post-show h-60 w-60 relative" v-for = "img in postData.imgs" :key="img">
                        <img class="object-cover max-h-full max-w-full"  :src="img" alt="">
                        <input type="file" class="absolute inset-0 w-full h-full cursor-pointer opacity-0" @change="editUploadedImg($event,img)"  accept="image/png, image/jpeg">
                    </div>
                </div>
                <div class="input">
                    <label for="" class = "requier"> صور اضافية </label>
                    <div class="field">
                        <input type="file" multiple class = "w-full h-fulll" @change="getPostImg($event)">
                    </div>
                </div>
                <div class="input">
                    <label for="" class = "requier"> التاريخ </label>
                    <input type="date" v-model = "postData.date" class = "field">
                </div>
                <div class="input">
                    <label for="" class = "requier"  > العنوان (انكليزي) </label>
                    <input type="text"  v-model="postData.title_en" class = "field">
                </div>
                <div class="input">
                    <label for="" class = ""> العنوان (عربي) </label>
                    <input type="text"  v-model="postData.title_ar" class = "field">
                </div>
                
                <div class="input">
                    <label for="" class = "requier"> الوصف (أنكليزي) </label>
                    <textarea  v-model="postData.body_en" class = "field h-40"></textarea>
                </div>

                <div class="input">
                    <label for="" class = ""> الوصف (عربي) </label>
                    <textarea  class = "field h-40" v-model="postData.body_ar"></textarea>
                </div>

                <div class="controll col-span-full flex justify-end py-2">
                    <button class = "btn main-btn" @click.prevent="post()" :class = "[postLoader ? 'opacity-60 cursor-not-allowed' : '' , 'btn main-btn']" :disabled = "postLoader"> أضافة </button>
                </div>

            </form>
        </div>
    </div>
    <div class="list-form">
        <div class="container">
            <div class = "section-title">
                <span class = "text-xl">Items List</span>
            </div>
            
            
            //- <div class="filters" @change = "filtering()">
            //-     <div class="name input">
            //-         <label for="" class = ""> Filter By Name </label>
            //-         <input type="search" placeholder = "Filter By Name" class = "field" v-model="filterData.project_title_en">
            //-     </div>
            //-     <div class="visible input">
            //-         <label for="" class = ""> Filter By Visible </label>
            //-         <select class = "field" name="" id="" v-model="filterData.visible">
            //-             <option value="both">Both</option>
            //-             <option value="true">active</option>
            //-             <option value="false">Disactive</option>
            //-         </select>
            //-     </div>
            //-     <div class="status input">
            //-         <label for="" class = ""> Filter By Status </label>
            //-         <select class = "field" name="" id="" v-model="filterData.active">
            //-             <option value="both">Both</option>
            //-             <option value="true">active</option>
            //-             <option value="false">Disactive</option>
            //-         </select>
            //-     </div>
            //- </div> 
            
            <div class="list relative">
                <get-loader class = "absolute inset-0"></get-loader>
                table(id = "myTable" class = "w-full over")
                    thead(class = " text-left text-gray-500 text-sm md:text-lg")
                        tr
                            th(class = " pr-4 border-b-2") #
                            th(class = " pr-4 border-b-2") صورة الغلاف
                            th(class = " pr-4 border-b-2") العنوان
                            th(class = " pr-4 border-b-2") الوصف
                            th(class = " pr-4 border-b-2") التاريخ
                            th(class = " pr-4 border-b-2") التعديل
                            
                            
                    tbody
                        tr(class='odd:bg-white even:bg-gray-100 duration-500 text-md' v-for = "(project,index) in projects" :key="project.id")
                            td(class = "font-bold lg:pl-4 border-b py-0 md:py-3") {{index + 1}}
                            td(class = "font-bold lg:pl-4 border-b py-0 md:py-3") 
                                a(:href = "project.content.cover" target = "_blanck")
                                    img(:src = "project.content.cover" class = "w-12 h-12 object-cover rounded")
                            td(class = "font-bold lg:pl-4 border-b py-0 md:py-3") {{project.content.title_ar}}
                            td(class = "font-bold lg:pl-4 border-b py-0 md:py-3") {{splitText(project.content.body_ar,50)}}
                            td(class = "font-bold lg:pl-4 border-b py-0 md:py-3") {{project.content.date}}

                            td(class = "border-b py-0 md:py-3")
                                ul(class = "list flex items-center")
                                    li(class = "text-gray-500 text-xl cursor-pointer mr-3 duration-500 hover:text-blue-500" @click="editInit(project)")
                                        <i class="fas fa-pencil-alt"></i>
                                
                                    li(class = "text-gray-500 text-xl cursor-pointer mr-3 duration-500 hover:text-red-500" @click="delItem(project)")
                                        <i class="fas fa-trash-alt"></i>
                                
            </div>
        </div>
    </div>
</div>
</template>
<style>
    header{
        border-radius: 0 0 50% 50%;
    }
                    @media 
only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px) {
	#ProjectsPage table td:nth-of-type(1):before { content: "#"; }
	#ProjectsPage table td:nth-of-type(2):before { content: "صورة الغلاف"; }
	#ProjectsPage table td:nth-of-type(3):before { content: "العنوان"; }
	#ProjectsPage table td:nth-of-type(4):before { content: "الوصف"; }
	#ProjectsPage table td:nth-of-type(5):before { content: "التاريخ"; }
	#ProjectsPage table td:nth-of-type(6):before { content: "التعديل"; }
}
</style>
<script>
import axios from 'axios'
export default {
    data(){
        return{
            imgs:[],
            postData:{
                title_en:'',
                title_ar:'',
                body_ar:'',
                body_en:'',
                cover:'',
                date:'',
                imgs:[],
            },
            editData:{},
            tempData:{},
            filterData:{
                project_title_en:"",
                visible:"both",
                active:'both',
            },
            column:{
                img_info:{
                    name:'Img',
                    key:'primary_image'
                },
                other_value:[
                    {
                        name:'Title',
                        key:'project_title_en'
                    },
                    {
                        name:'Body',
                        key:'sub_body'
                    },
                    {
                        name:'Project Type',
                        key:'project_type_title_en'
                    },
                    {
                        name:'Visible',
                        key:'visible'
                    },
                    {
                        name:'status',
                        key:'status'
                    },
                ],
                config:{
                    index: true,
                    img: true,
                    attach: false,
                    pagin: false,
                    filters:false,
                    actions:true,
                    filter:['Seasons' , 'Name' , "Status"],
                    action:['Edit','Delete'],
                    link:''
                },
            }
        }
    },
    computed:{
        showImgCh(){
           return this.showImg == "" ? true : false
        },
        showEditImgCh(){
            return this.showEditImg == "" ? true : false
        }
    },
    methods:{
        filtering(){
            this.getProjects(this.filterData)
        },
        getCoverImg(event){
            console.log(event.target.files)
            for ( var key in event.target.files ){
                axios.post('/image',this.dataReutrn({image:event.target.files[key]})).then(response =>{
                    this.postData.cover = response.data.data
                    console.log(this.postData)
                })
            }
        },  
        getPostImg(event){
            this.imgs = event.target.files
            console.log(event)
            for ( var key in  event.target.files ){
                console.log(parseInt(key))
                axios.post('/image',this.dataReutrn({image:event.target.files[key]})).then(response =>{
                    this.postData.imgs.push(response.data.data)
                })
            }
        },
        editUploadedImg(event,img){
            for ( var key in event.target.files ){
                axios.post('/image',this.dataReutrn({image:event.target.files[key]})).then(response =>{
                    this.postData.imgs[this.postData.imgs.findIndex(img_in=> img_in ==img )] = response.data.data
                })
            }
        },
        post(){
           this.createProject({"content":JSON.stringify(this.postData)}).then(()=>{
                this.postData={
                    title_en:'',
                    title_ar:'',
                    body_ar:'',
                    body_en:'',
                    cover:'',
                    imgs:[],
                }
                this.getProjects()
           })
        },
        editInit(project){
            this.toggleEditStatus()
            this.editData = project            
            this.editData = {
                id:project.id,
                title_en : project.content.title_en,
                title_ar : project.content.title_ar,
                body_en : project.content.body_en,
                body_ar : project.content.body_ar,
                date : project.content.date,
                cover : project.content.cover, 
                imgs : project.content.imgs, 
            } 
        },
        update(){
            this.updateProject(this.editData).then(()=>{
                this.toggleEditStatus()
                this.getProjects()
           })
        },
        delItem(project){
            if(confirm(this.alertMsg)){
                this.deleteProject(project.id).then(()=>{
                    this.getProjects()
                })
            }
        },
        closeEdit(){
            this.toggleEditStatus()        
        }
    },
    watch:{
        projectTypes(value){
            this.types = value
        }
    },
    mounted(){
        this.getProjects()
    }
}
</script>