import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import "./styles/style.css"
import { mapGetters ,mapActions , mapMutations } from 'vuex'
import Data_Table from "./components/Globle/Data_Table.vue"

import _min_nav from "./components/Globle/_min_nav.vue"
import _header_page from "./components/Globle/_header_page.vue"
import _get_loader from "./components/Globle/_get_loader.vue"

import 'sweetalert2/dist/sweetalert2.min.css'
import moment from "moment"
var mixin = {
    data(){
        return{
            baseUrl:'http://162.214.159.53:8020/api/',
            alertMsg:"You won't be able to revert this!"
        }
    },
    computed:{
        ...mapGetters([
            'getLoader',
            'postLoader',
            'editStatus',
            'sideBarStatus', 
            'user',
            'about',
            'licenses',
            'projects',
            'socials',
            'userInfo'

        ]),
        getUserName(){
            return  'name'  //JSON.parse(localStorage.getItem('il_user_Info')).full_name || false
        },
        getUserEmail(){
            return 'email' //JSON.parse(localStorage.getItem('il_user_Info')).email || false
        },
    },
    methods: {
        ...mapMutations(['toggleEditStatus','toggleSideBarStatus']),
        ...mapActions([
            'login',

            'getUser',
            'updateUser',

            'getAbout',
            'updateAbout',

            'getProjects',
            'createProject',
            'updateProject',
            'deleteProject',

            'getLicenses',
            'createLicens',
            'updateLicens',
            'deleteLicens',

            'getSocials',
            'createSocial',
            'updateSocial',
            'deleteSocial',
        ]),
        scrollToElemnt(el){
            window.scrollTo({
                top: document.querySelector("." + el).offsetTop,
                behavior:"smooth"
            })
        },
        getFormDate(value){
            return moment(value).format('DD/MM/YYYY hh:mm A')
        },
        dataReutrn(obj){
            
            var data = new FormData();

            for(var key in obj){
                data.append( key , obj[key] )
            }

            return data

        },
        getImg(event,obj,key,view_key){
            this[view_key] = URL.createObjectURL(event.target.files[0])
            this[obj][key] = event.target.files[0]
        },
        splitText(text,length){
            if(text !== null && text.length > length ){
                return text.slice(0,length) + '...'
            }else{
                return text
            }
        }
    },
}


createApp(App)
.use(store)
.use(router)
.component('data-table',Data_Table)
.component('min-nav',_min_nav)
.component('page-header',_header_page)
.component('get-loader',_get_loader)
.mixin(mixin)
.mount('#app')
