import { createStore } from 'vuex'
import users from "./modules/Users"

import about from "./modules/about.js"
import Projects from "./modules/Projects.js"
import Licenses from "./modules/Licenses.js"
import SocialMedia from "./modules/SocialMedia.js"
import auth from "./modules/auth.js"

export default createStore({
  state: {
    editStatus:false,
    sideBarStatus:true,
    loadingData:false,

    getLoader:false,
    postLoader:false,


  },
  getters:{
    editStatus:state => state.editStatus,
    sideBarStatus:state => state.sideBarStatus,
    getLoader: state => state.getLoader,
    postLoader: state => state.postLoader,
    
  },
  mutations: {
    toggleEditStatus(state) {
      state.editStatus = !state.editStatus
    },
    toggleSideBarStatus(state) {
      state.sideBarStatus = !state.sideBarStatus
    },
  },
  actions: {
  },
  modules: {
    auth,
    users,
    about,
    Projects,
    SocialMedia,
    Licenses
  }
})
