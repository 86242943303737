import $http from "@/plugins/axios.js"

export default {
    state:{
        projects:[]
    },
    getters:{
        projects: state => state.projects
    },
    actions:{
        async getProjects({commit}){
            const response = await $http.get(`/our-works`)
            console.log(response.data)
            response.data.data.data.map(item=>{
                item.content = JSON.parse(item.content)
            })
            commit("SET_PROJECTS", response.data.data.data.reverse());
        },

        async createProject({commit},project){
            await $http.post('/our-works',project)
            return commit
            // commit('ADD_NEW_PROJECT',response.data.data)
        },

        async updateProject({commit} , project){
            await $http.put(`/our-works/${project.id}`,{"content":JSON.stringify(project)})
            return commit
        },
        async deleteProject({commit} , id){
            await $http.delete(`/our-works/${id}`)
            return commit
        }
    },
    mutations:{
        SET_PROJECTS(state,data){
            state.projects = data
        },
        ADD_NEW_PROJECT(state,data){
            state.projects.unshift(JSON.parse(data))
        },
        UPDATE_PROJECT(state,data){
            state.projects  = state.projects.map(item => item = item.id == data.id ? data : item)
        },
        DELETE_PROJECT(state,id){
            state.projects  = state.projects.filter(item => item.id !== id)
        },
        
    }
}