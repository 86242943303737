<template>
    <div id="About" class = "pb-8">
        <page-header title = "About" icon="fas fa-info"></page-header>
        <div class="add-form -mt-10 bg-white w-11/12 mx-auto py-4 rounded shadow mb-3 relative">
            <get-loader class = "absolute inset-0 z-40 h-full" v-show = "getLoader || postLoader"></get-loader>
            <div class="container">
                <div class = "section-title">
                    <span class = "text-xl">عن المؤسسة</span>
                </div>
                <form action="" class = "grid grid-cols-1 md:grid-cols-2 gap-2">
                    <!-- <div class="about-imgs col-span-full grid grid-cols-2 md:grid-cols-4 gap-2">
                      <div class="imges h-38 relative" v-for = "item in pages_img" :key="item.id">
                        <div @click="delItem(item)" class="delet_img absolute inset-0 bg-red-600 bg-opacity-40 text-white text-2xl flex-center cursor-pointer opacity-0 hover:opacity-100 duration-500">
                          <i class="fas fa-trash-alt"></i>
                        </div>
                        <img :src="baseUrl + item.img_path" class = "h-full w-full object-cover" alt="">
                      </div>
                      <div class="adding_image h-38 flex-center border-4 border-gray-300 relative" v-if = "pages_img.length < 4">
                        <div class="cont text-center text-gray-300 font-bold">
                          <i class="fas fa-plus text-2xl"></i>
                          <h2> Upload Image </h2>
                        </div>
                        <input type="file" @change = "uploadImage($event)" class = "absolute inset-0 opacity-0 cursor-pointer">
                      </div>
                    </div> -->
                    <div class="input">
                        <label for="" class = "requier"> العنوان (انكليزي) </label>
                        <input type="text" v-model="postData.title.en" class = "field">
                    </div>
                    <div class="input">
                        <label for="" class = "requier"> العنوان (عربي) </label>
                        <input type="text" v-model="postData.title.ar" class = "field">
                    </div>

                    <div class="input">
                        <label for="" class = "requier"> عدد الاطفال تحت الرعاية </label>
                        <input type="text" v-model="postData.childCount" class = "field">
                    </div>

                    <div class="input">
                        <label for="" class = "requier"> الحملات </label>
                        <input type="text" v-model="postData.projects" class = "field">
                    </div>

                    <div class="input">
                        <label for="" class = "requier"> المبلغ المصروف </label>
                        <input type="text" v-model="postData.charges" class = "field">
                    </div>

                    <div class="input">
                        <label for="" class = "requier"> رقم الهاتف </label>
                        <input type="text" v-model="postData.phone" class = "field">
                    </div>

                    <div class="input">
                        <label for="" class = "requier"> البريد الالكتروني </label>
                        <input type="text" v-model="postData.email" class = "field">
                    </div>
                    
                    <div class="input">
                        <label for="" class = "requier"> العنوان </label>
                        <input type="text" v-model="postData.address" class = "field">
                    </div>


                    
                    <div class="input">
                        <label for="" class = ""> عن المؤسسة (انكليزي) </label>
                        <textarea  v-model="postData.description.en" class = "field h-40"></textarea>
                    </div>
                    <div class="input">
                        <label for="" class = ""> عن المؤسسة (عربي) </label>
                        <textarea  class = "field h-40" v-model="postData.description.ar"></textarea>
                    </div>

                    <div class="controll">
                        <button :class = "[postLoader ? 'opacity-60 cursor-not-allowed' : '' , 'btn main-btn']" :disabled = "postLoader" @click.prevent="edit_item()"> حفظ </button>
                    </div>

                </form>
            </div>
        </div>
    </div>
</template>
<style>
    header{
        border-radius: 0 0 50% 50%;
    }
</style>
<script>
export default {
    data(){
        return{
            postData:{
                title:{
                    en:'',
                    ar:''
                },
                description:{
                    en:'',
                    ar:''
                },
                childCount:0,
                projects:0,
                charges:'',
                phone:'',
                address:'',
                email:'',
                images:[]
            },
            addImage:{
              websitepage_id : 4 ,
              files:'',
            }, 
        }
    },
    methods:{
        edit_item(){
            console.log(JSON.stringify(this.postData))
            this.updateAbout(JSON.stringify(this.postData))
        },
        delItem(item){
            if(confirm(this.alertMsg)){
                this.deletePageImg(item.id)
            }
        },
    },
    watch:{
      page_info(value){
        this.postData = value
      }
    },
    mounted(){
        this.getAbout().then(()=>{
          this.postData = this.about
        })
    }
}
</script>