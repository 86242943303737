
import $http from "@/plugins/axios.js"


export default {
    state:{
        userInfo:{}
    },
    getters:{
        userInfo: state =>  state.userInfo
    },
    actions:{
        async login({commit},data){
            const request = await $http.post('/auth/login',data)
            commit('SET_USER', request.data.data)
        }
    },
    mutations:{
        SET_USER(state,data){
            state.userInfo = data
        }
    }
}