<template>
    <div id="layoutPage" :class = "[sideBarStatus? 'md:pr-64' : 'pr-0' , 'duration-700 rtl']">
        <sidebar></sidebar>
        <router-view></router-view>
    </div>
</template>
<script>

import sidebar from "../components/Globle/sidebar.vue"
export default{
    data(){
        return{

        }
    },
    components:{
        sidebar,
    },
    mounted(){
        if(window.innerWidth < 768){
            this.toggleSideBarStatus()
        }
        if(localStorage.getItem('sama-info') == null) {
            this.$router.push('/')
        }
    }
}

</script>
