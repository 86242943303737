<template lang="pug">
table(id = "myTable" class = "w-full over" v-if ="data.length !== 0")
    thead.text-left.text-gray-500(class = "text-sm md:text-lg")
        tr
            th.pl-4.border-b-2( v-if = "cols.config.index" ) #
            th.border-b-2( v-if = "cols.config.img" ) {{cols.img_info.name}}
            th.border-b-2( v-if = "cols.config.attach" ) {{cols.attach_info.name}}
            th.border-b-2(v-for = "col in cols.other_value") {{col.name}}
            th.border-b-2(v-if = "cols.config.actions") Edit
            
    tbody
        tr(v-for = "(item,index) in data" :key="item.id" class='odd:bg-white even:bg-gray-100 duration-500 text-md')
            td.font-bold(class = "lg:pl-4 border-b py-0 md:py-3") 
                span {{index + 1}}
            td.border-b(v-if = "cols.config.img" class = "py-0 md:py-3")
                a(:href = "baseUrl + item[cols.img_info.key]" target = "_blanck")
                    img(:src = "baseUrl + item[cols.img_info.key]" class = "w-12 h-12 object-cover rounded")
            
            td.border-b(v-if = "cols.config.attach"  class = "py-0 md:py-3 font-bold text-blue-400 underline")
                a(:href = "baseUrl + item[cols.attach_info.key]" v-if = "cols.attach_info.type == 'link'" target = "_blanck") {{cols.attach_info.name}}
                a(:href = "baseUrl + item[cols.attach_info.key]" target = "_blanck" v-else) {{cols.attach_info.name}}
                   

            template(v-for="val in cols.other_value")
                td.border-b(v-text = "item[val.key]" class = "py-0 md:py-3")


            
            td.border-b(v-if = "cols.config.actions" class = "py-0 md:py-3")
                ul(class = "list flex items-center")
                    template(v-if = "cols.config.action.includes('View')")
                        li.text-gray-500.text-xl.cursor-pointer.mr-3(class = "duration-500 hover:text-green-500")
                            router-link(:to = "cols.config.link + item.id")
                                <i class="fas fa-eye"></i>
                    template(v-if = "cols.config.action.includes('Edit')")
                        li.text-gray-500.text-xl.cursor-pointer.mr-3(class = "duration-500 hover:text-blue-500" @click = "edit(item)")
                            <i class="fas fa-pencil-alt"></i>
                    template(v-if = "cols.config.action.includes('Delete')")
                        li.text-gray-500.text-xl.cursor-pointer.mr-3(class = "duration-500 hover:text-red-500" @click = "deletItem(item)")
                            <i class="fas fa-trash-alt"></i>
                    template(v-if = "cols.config.action.includes('Accept')")
                        li.text-gray-500.text-xl.cursor-pointer.mr-3(class = "duration-500 hover:text-green-500" @click = "acceptMember(item)")
                            <i class="fas fa-check"></i>
                    template(v-if = "cols.config.action.includes('Reject')")
                        li.text-gray-500.text-xl.cursor-pointer.mr-3(class = "duration-500 hover:text-red-500" @click = "rejectItem(item)")
                            <i class="fas fa-times"></i>
    //- .table-controll
    //-     .item-page
//- .hint.opacity-70(v-else)
//-     img(src = "../../assets/Img/list.svg" class = "w-56 mx-auto")
//-     h1.font-bold.text-indigo-500.text-center.text-2xl No data available !
</template>
<style>
    @media 
only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px)  {

	/* Force table to not be like tables anymore */
	table, thead, tbody, th, td, tr { 
		display: block; 
        border-spacing: 0px 5px;
	}
	
	/* Hide table headers (but not display: none;, for accessibility) */
	thead tr { 
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
	
	tr { border: 1px solid #ccc; margin-bottom: 5px;}
	
	td { 
		/* Behave  like a "row" */
		border: none;
		border-bottom: 1px solid #eee; 
		position: relative;
		padding-left: 50%; 
	}
	
	td:before { 
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		top: 0px;
		left: 6px;
		width: 45%; 
		padding-right: 10px; 
		white-space: nowrap;
        font-weight: bold;
        height: 100%;
        display: flex;
        align-items: center;
	}
	
	/*
	Label the data
	*/
}
</style>
<script>
export default {
    
    emits:['edit','acceptMember','reject','deletItem','filters'],
    props:['cols',"data"],

    data(){
        return{}
    },

    methods:{

        edit(item){
            this.$emit('edit',item)
        },
        filters(){
            this.$emit('filters',this.filterInfo)
        },
        deletItem(item){
            this.$emit('deletItem',item)
        },
        acceptMember(item){
            this.$emit('acceptMember',item)
        },
        rejectItem(item){
            this.$emit('reject',item)
        },
    },
}
</script>