<template>
    <div id="LoginPage" class = "bg-main rtl">
        <div class="container p-0 h-screen flex-center">
            <form action="" class = "bg-gray-300 w-3/4 md:w-2/4 rounded-lg pt-3">
                <div class="form-head">
                    <h1 class = "text-center mb-3 font-bold text-xl">تــسجيل الدخول</h1>
                </div>
                <div class="form-content bg-white rounded-lg py-3 px-10">
                    <div class="input mb-3">
                        <label for="" class = "text-main block w-full font-bold">البريد الالكتروني</label>
                        <input type="text" placeholder = "ali@gmail.com" v-model="postData.email" class = "border-b-2 px-1 py-2 w-full text-sm outline-none">
                    </div>
                    <div class="input mb-3">
                        <label for="" class = "text-main block w-full font-bold"> كلمة السر </label>
                        <input type="passord" placeholder = "****" v-model="postData.password" class = "border-b-2 px-1 py-2 w-full text-sm outline-none">
                    </div>
                    <div class="controll ">
                        <button @click.prevent="post()" :class = "[postLoader ? 'opacity-60 cursor-not-allowed' : '' , 'bg-main text-white font-bold w-full px-2 py-3']" :disabled = "postLoader"> دخول </button>
                        
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
<style>
.avatar::before{
    content: "";
    display: block;
    background: #fff;
    opacity: .2;
    width: 300px;
    height: 300px;
    position: absolute;
    top: -60px;
    right: -60px;
    border-radius: 50%;
    z-index: -1;
}
.avatar::after{
    content: "";
    display: block;
    background: #fff;
    opacity: .2;
    width: 500px;
    height: 500px;
    position: absolute;
    bottom: -100px;
    left: -100px;
    border-radius: 50%;
    z-index: -1;
}
.avatar{
    border-radius: 0px 50px 50px 0px;
}

</style>
<script>
export default {
    
    data(){
        return{
            postData: {
                email: "",
                password: "",
            }
        }
    },
    methods:{
        post(){
            this.login(this.postData)
        }
    },
    watch:{
        userInfo(value){
            value.auth = window.btoa(value.email+':'+value.password)
            localStorage.setItem('sama-info',JSON.stringify(value))
            this.$router.push('/about')
        }
    },
    mounted(){
        if(localStorage.getItem('sama-info') !== null) {
            this.$router.push('/about')
        }
    }
}
</script>